import React from "react";

const Heading = ({ heading, subtitle }) => {
  return (
           <div className="text-center max-w-[600px] mx-auto space-y-2">
            <h1 className="text-3xl font-bold lg:text-4xl">{heading}</h1>
            <p className="text-sm font-bold font-titleFont ">{subtitle}</p>
          </div>
  );
};

export default Heading;
