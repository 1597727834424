import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import Pagination from "../../components/pageProps/shopPage/Pagination";
import ShopSideNav from "../../components/pageProps/shopPage/ShopSideNav";
import Heading from "../../components/home/Products/Heading";

const Shop = () => {
  const [itemsPerPage] = useState(48);
  
  // State to track if the screen size is small (mobile)
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  // Check screen size on initial load and on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 850);
    };

    // Initial check
    handleResize();
    // Debounce the resize handler to avoid excessive re-renders
    const debouncedResizeHandler = debounce(handleResize, 200);
    // Listen for window resize events
    window.addEventListener("resize",  debouncedResizeHandler);

    // Clean up event listener
    return () => {
      window.removeEventListener("resize",  debouncedResizeHandler);
    };
  }, []);

  function debounce(func, wait) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  return (
    <div className="max-w-container mx-auto px-4 py-8 xl:px-0">
      <Heading heading="OUR PRODUCT" subtitle="Explore our products"  />
      <Breadcrumbs  />

      {/* ================= Products Start here =================== */}
      <div className="w-full h-full flex pb-20 gap-10">
         {/* Conditionally render ShopSideNav based on screen size */}
        {!isMobileScreen && (
          <div className="w-[20%] lgl:w-[25%]  mdl:inline-flex">
            <ShopSideNav />
          </div>
        )}
        <div className="w-full mdl:w-[80%] lgl:w-[75%] h-full flex flex-col gap-10 ">
         {/* <ProductBanner itemsPerPageFromBanner={itemsPerPageFromBanner} />*/}
          <Pagination itemsPerPage={itemsPerPage} />
        </div>
      </div>
      {/* ================= Products End here ===================== */}
    </div>
  );
};

export default Shop;
