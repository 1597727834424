import React, { useState, useEffect } from "react";
import { ImPlus } from "react-icons/im";
import NavTitle from "./NavTitle";
import { useDispatch, useSelector } from "react-redux";
import { toggleCategory,clearCategories } from "../../../../redux/orebiSlice";

const Category = () => {
  const [showSubCatOne, setShowSubCatOne] = useState(false);

  const checkedCategorys = useSelector( (state) => state.orebiReducer.checkedCategorys);
  const dispatch = useDispatch();

  const category = [
    {
      _id: 90,
      title: "Earbuds",
    },
    {
      _id: 91,
      title: "Speakers",
    },
    {
      _id: 92,
      title: "Wearables",
      disabled: true,
    },
   
  ];
  const handleToggleCategory = (item) => {
    if (item.disabled) return;
    dispatch(toggleCategory(item));
  };

  const handleClearFilters = () => {
    dispatch(clearCategories());
  };

  // Effect to clear filters when navigating away from the page
  useEffect(() => {
    const clearFiltersOnUnmount = () => {
      dispatch(clearCategories());
    };

    // Add event listener for beforeunload to clear filters
    window.addEventListener("beforeunload", clearFiltersOnUnmount);

    return () => {
      // Clean up event listener when component unmounts
      window.removeEventListener("beforeunload", clearFiltersOnUnmount);
    };
  }, [dispatch]);

  return (
    <div className="w-full">
      <NavTitle title="Shop by Category" icons={true} className="dark:text-white" />
      <div>
        <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
          {category.map((item) => (
            <li
              key={item._id}
              className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2  hover:text-black dark:hover:text-white hover:border-red-400 duration-300"
            >
              <input
                type="checkbox"
                id={item._id}
                checked={checkedCategorys.some((b) => b._id === item._id)}
                onChange={() => handleToggleCategory(item)}
              />
              {item.title}
              {item.icons && (
                <span
                  onClick={() =>!item.disabled && setShowSubCatOne(!showSubCatOne)}
                  className={`text-[10px] lg:text-xs cursor-pointer text-gray-400 hover:text-primeColor duration-300 ${
                    item.disabled ? "cursor-not-allowed text-gray-300" : ""
                  }`}
                >
                  <ImPlus />
                </span>
              )}
            </li>
          ))}
           <li>
            <button
              onClick={handleClearFilters}
              className="text-sm text-gray-400 hover:text-black dark:hover:text-white duration-300"
            >
              Clear Filters
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Category;
