import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <>

    <div style={{ textAlign: 'center', padding: '250px' }} className='text-3xl'>
      <h1>404 - Page Not Found</h1>
      <p>The page you're looking for doesn't exist.</p>
      <Link to="/" className="text-primary hover:underline ">Go to Home</Link>
    </div>
    </>
  );
};

export default NotFound;