//product main product page
import React from "react";
import { MdOutlineLabelImportant } from "react-icons/md";
import Image from "../../designLayouts/Image";
import { useNavigate } from "react-router-dom";

const Product = (props) => {
  const _id = props.productName;
  const idString = (_id) => {
    return String(_id).toLowerCase().split(" ").join("");
  };
  const rootId = idString(_id);
  const navigate = useNavigate();
  
  const handleProductDetails = () => {
    navigate(`/product/${rootId}`, {
      state: {
        item: props,
      },  
    }); 
  };

  return(
    <div className="container mx-auto relative group">
      <div className="object-cover sm:w-[350px] h-[480px] relative overflow-y-hidden border-b-[1px] border-t-[1px] border-l-[1px] border-r-[1px]">
        <div onClick={handleProductDetails}>
          <Image className="object-fit:cover sm:w-[350px] h-[480px] group-hover:cover    drop-shadow-[-8px_4px_6px_rgba(0,0,0,.4)] group-hover:backdrop-blur-sm group-hover:hover:scale-110 items-center justify-between  duration-500"
           imgSrc={props.thumbnail || props.img} />
        </div>
        <div className="absolute top-6 left-8 ">
         {/* {props.badge && <Badge text="New" />}*/}
        </div>
        <div className="w-full h-19 absolute bg-white -bottom-[130px] group-hover:bottom-0 duration-700">
          <ul className="w-full h-full flex flex-col items-end justify-center gap-2 font-titleFont px-2 border-l border-r">
            <li
              onClick={handleProductDetails}
              className="text-[#767676] hover:text-primeColor text-sm font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 hover:cursor-pointer pb-1 duration-300 w-full"
            >
              View Details
              <span className="text-lg">
                <MdOutlineLabelImportant />
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="max-h-full  sm:w-[350px] leading-7 py-1 flex flex-col  border-[1px] border-t-0  px-4">
        <div className="flex font-semibold items-center justify-between font-titleFont">
          <h2 className="text-xl text-brandWhiteprime font-bold">
            {props.productName}
          </h2>
        </div>
        <p className="text-brandWhiteprime font-titleFont font-normal text-lg">
          {props.color}
        </p>
        </div>
        </div>
  );
 };

export default Product;
