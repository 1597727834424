import React, { useEffect, useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import {Black, White} from "../../assets/images";
import Heading from "../../components/home/Products/Heading";

const Contact= ( ) => {
  const {theme } = useOutletContext();
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");

  useEffect(() => {
    if (location.state && location.state.data) {
      setPrevLocation(location.state.data);
    }
  }, [location]);

  const [clientName, setClientName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [messages, setMessages] = useState("");

  const [errClientName, setErrClientName] = useState("");
  const [errPhone, setErrPhone] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errMessages, setErrMessages] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const handleName = (e) => {
    const value = e.target.value;
    if (!/\d/.test(value)) { // Check if there are no digits
      setClientName(value);
      setErrClientName("");
    } else {
      setErrClientName("Name should not contain numbers");
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Allow only digits
    setPhone(value); // Update phone value

    // Real-time validation
    if (value.length > 10) {
      setPhone(value.slice(0, 10)); // Limit to 10 digits
    } else {
      setErrPhone(''); // Clear error message while typing
    }
  };

  // Function to handle input blur (when user stops typing)
  const handlePhoneBlur = () => {
    if (phone.length !== 10) {
      setErrPhone('Phone number should be exactly 10 digits'); // Set error message if length is incorrect
    } else {
      setErrPhone(''); // Clear error message if length is correct
    }
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };

  const handleMessages = (e) => {
    setMessages(e.target.value);
    setErrMessages("");
  };

  const isValid = () => {
    let flag = true;
    let error = [];
    
    if (clientName.trim().length === 0) {
      error.push("Please enter your name");
      flag = false;
    }
    if (phone.trim().length === 0) {
      error.push("Please enter a valid phone number");
      flag = false;
    }
    if (email.trim().length === 0) {
      error.push("Please enter your email");
      flag = false;
    } else if (!EmailValidation(email)) {
      error.push("Please enter a valid email");
      flag = false;
    }
    if (messages.trim().length === 0) {
      error.push("Please enter your message");
      flag = false;
    }
    if (!flag) alert(error.join(", "));
    return flag;
  };
  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };

     const handlePost = (e) => {
    e.preventDefault();

    if (!isValid()) return;

    // Send WhatsApp message
    sendWhatsAppMessage();  

    // Display success message
    setSuccessMsg(
      `Thank you, ${clientName}! Your message has been sent.`
    );  

    if (!clientName) {
      setErrClientName("Please enter your name.");
      return;
    }

    if (!EmailValidation(email)) {
      setErrEmail("Please enter a valid email address.");
      return;
    }

    if (!messages) {
      setErrMessages("Please enter your message.");
      return;
    }
    
    // Clear form fields after successful submission
      setClientName("");
      setPhone("");
      setEmail("");
      setMessages("");
   
  };
  const sendWhatsAppMessage = () => {
    let wpMessage = encodeURI(
      `My name is ${clientName}. My phone number is ${phone}. My message is: ${messages}`
    );
    let whatsappLink = `https://wa.me/+919409767676?text=${wpMessage}`;

    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="overflow-hidden min-h-[550px]  flex justify-center hover:text-black dark:hover:text-white duration-200 p-2     items-center py-8">
      <div className="container mx-auto ">
        <Heading
          heading="CONTACT US"
          subtitle="NBUZZ : Elevate your sound experience with us... "
        />
        <Breadcrumbs prevLocation={prevLocation} />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 items-center text-black dark:text-gray-400  about-bg-color">
          <div data-aos="zoom-in" data-aos-duration="1500">
            <img
              src={theme === "light" ? Black : White}
              alt=""
              className="scale-105 w-[250px] md:w-[240px] h-[280px] pt-20 sm:py-12 mx-auto align-center  object-cover"
            />
          </div>
          <form  onSubmit={handlePost} className="w-[500px] h-auto py-6 flex flex-col gap-6">
            <h1 className="font-titleFont font-semibold text-3xl">
              Fill up a Form
            </h1>
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Name
              </p>
              <input
                onChange={handleName}
                value={clientName}
                className=" pl-2 w-80 py-2  border-b-2  border-l-4  border-r-2 border-t-2   px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-white-300"
                type="text"
                placeholder="Enter your name here"
                required
              />
              {errClientName && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errClientName}
                </p>
              )}
            </div>
            <div>
            <p className="text-base font-titleFont font-semibold px-2">
              Phone Number
            </p>
            <input
              type="text"
              value={phone}
              onChange={handlePhoneChange}
              onBlur={handlePhoneBlur} // Validate on blur
              className="w-80 py-1  border-b-2  border-l-4  border-r-2 border-t-2  px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
              placeholder="Enter your phone number here"
              maxLength={10} // Limit input length to 10 digits
              required
            />
            {errPhone && (
              <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                <span className="text-sm italic font-bold">!</span>
                {errPhone}
              </p>
            )}
          </div>
            <div>
              <label
                className="text-base font-titleFont font-semibold px-2"
                htmlFor="email"
              >
                Email Address
              </label>
              <input
                id="email"
                type="email"
                name="email"
                onChange={handleEmail}
                value={email}
                className="flex pl-2 w-80 py-2  border-b-2  border-l-4  border-r-2 border-t-2    px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                placeholder="Enter your email here"
                required
              />
        
              {errEmail && (
                <p className=" text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errEmail}
                </p>
              )}
            </div>
            <div>
              <label
                className="text-base font-titleFont font-semibold px-2"
                htmlFor="message"
              >
                Message
              </label>
              <textarea
                onChange={handleMessages}
                value={messages}
                className="flex pl-2 w-80 py-2  border-b-2  border-l-4  border-r-2 border-t-2    px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                placeholder="Enter your message here"
                required
                rows="3"
                cols="30"
                id="message"
                name="message"
              />
             
            </div>
            <button
         
              className="w-44  bg-primeColor text-gray-200 h-10 font-titleFont text-base tracking-wide font-semibold hover:text-black dark:hover:text-white duration-200 p-2"
         
            
            >
             Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
