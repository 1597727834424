import React from "react";
import styled from "styled-components";

const Control = ({ img, title, description }) => {
  return (
    <ControlWrapper>
      {img && <img src={img} alt={title} className="w-50 h-50 control-icon text-5xl text-primary group-hover:text-black duration-500" />}
      <h5 className="control-title">{title}</h5>
      <p className="control-description">{description}</p>
    </ControlWrapper>
  );
};

export default Control;

// Styled component wrapper
const ControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .control-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
    background-color: rgba(220, 220, 220, 0.5);
    border-radius: 30%;
    padding: 0.6rem;
  }

  .control-title {
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase;
  }
`;


