import React from "react";
import { Helmet } from "react-helmet";
import Banner from "../../components/Banner/Banner";
import BannerBottom from "../../components/Banner/BannerBottom";
import BestSellers from "../../components/home/BestSellers/BestSellers";
import NewArrivals from "../../components/home/NewArrivals/NewArrivals";
import SpecialOffers from "../../components/home/SpecialOffers/SpecialOffers";
import { BCpod1, NBS1002, } from "../../assets/images";
import BestSellers1 from "../../components/home/BestSellers/BestSellers1";

const BannerData = {
  discount: "C-PODS",
  title: "Elite Earbuds",
  date: "Long-lasting battery life",
  image: BCpod1,
  title2: "Wireless Freedom",
  title3: "Your Sound, Your Style",
  title4:
    "Experience crystal-clear audio and all-day comfort with our premium earbuds. Perfect for music lovers and active lifestyles. ",
  bgColor: "purple", 
  buttonLink: "/category/Earbuds",
};

const BannerData1 = {
  discount: "NBS1002",
  title: "PARTY SPEAKER",
  date: " Built-in LED lights for added visual excitement",
  image: NBS1002,
  title2: " Available now",
  title3: "Your Gateway to superior Sound",
  title4:
    "Introducing the Ultimate Party Speaker, designed to deliver buzzing sound and vibrant LED lights for an unforgettable party experience.",
  bgColor: "blue",
  buttonLink: "/category/Speakers",
};
const Home = () => {
  return (
    <div className="max-w-container mx-auto">
        <Helmet>
        <title>
        NBUZZ | Experience Redefined
        </title>
        <meta
          name="keywords"
          content="homepage, contact, address, social media, earbuds, party Speaker, NewArrival, team, support, service"
        />
        <meta
          name="description"
          content="NBUZZ vision is to build, design, and manufacture the next generation Audio & Life-Style products aimed to deliver innovative high-quality equipment which meets international standards, indigenously developed to serve new age requirements.."
        />
      </Helmet>
      <Banner />
      <BannerBottom />
      <div className="max-w-container mx-auto px-4">
        {/* <Sale/> */}
        <BestSellers data={BannerData}/>
        <NewArrivals />
        <BestSellers1 data={BannerData1}/>
        <SpecialOffers/>
        {/* <YearProduct /> */}
        
      </div>
    </div>
  );
};

export default Home;
