import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import Heading from "../../components/home/Products/Heading";

const About = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");

  useEffect(() => {
    // Ensure location.state exists and has data property
    if (location.state && location.state.data) {
      setPrevLocation(location.state.data);
    } else {
      // Fallback or default value if state.data is not available
      setPrevLocation("Default Previous Location");
    }
  }, [location]);

  return (
    <div className="overflow-hidden min-h-[550px] flex justify-center items-center py-8 sm:px-8 ">
      <div className="container about">
        <Heading
          heading="ABOUT US"
          subtitle="NBUZZ : A Legacy of Innovation for Generation "
        />

        <Breadcrumbs prevLocation={prevLocation} />
        <div
          className="grid grid-cols-1 gap-2 items-center
           text-black about-bg-color "
        >
          <div>
            <div className="space-y-3 sm:p-16 pb-6 pl-4 pr-4">
              <h1 className="text-2xl sm:text-4xl font-bold font-serif"></h1>
              <p className="tracking-wide">
                At NBUZZ, we Started our journey in 2022 as a part of the
                LifeStyle Division of N-Labs which was a part of HVC, which had
                the legacy of providing an assortment of various A.V. Equipment
                with pan India presence of over 3 decades in AV Industry .
              </p>

              <p>
                {" "}
                our vision was to built, design and manufacture the next
                generation Audio & Life-Style products aimed to deliver
                innovative high quality equipments which meets international
                standards, indigenously developed to serve new Age requirements.
              </p>
              <p>
                {" "}
                But Seeing the Trends We Expanded our R&D in Developing Next
                Generation Products making “NBUZZ” a Stand Alone Company named
                as NBUZZ AVL LIMITED, which Means New Buzzing Experience which
                Creates a Buzz in Your Existing Life-Style!
              </p>

              <p>
                Our main Goal is to Solve the Problems faced in the current Busy
                LifeStyle with our Latest Technological products which are Party
                Speakers, Microphones, Wired & Wireless Wearables and so on…..
              </p>
              <p>
                Our products are Designed for varied applications and everything
                is done in-house. This helps us in controlling the whole process
                and being a truly innovative and cost-effective Indian Audio
                Video & Life-Style Company.
              </p>
              <p>
                We believe in manufacturing AVL products for a variety of
                applications in-house helping us in controlling the whole
                process with continuous improvements as and when required.
              </p>

              <p className="leading-8 tracking-wide">
                We are Committed in providing comprehensive after sales Service
                & support improving customer experience and enhancing your
                listening experience to the next level.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
