import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { HiMenuAlt2 } from "react-icons/hi";
import { motion } from "framer-motion";
import {
  logo1_light,
  logo_dark,
} from "../../../assets/images";

import { navBarList } from "../../../constants";
import Flex from "../../designLayouts/Flex";
import { toggle_Dark, toggle_Light } from "../../../assets/images";


const Header = ({theme, setTheme}) => {
  const [showMenu, setShowMenu] = useState(true);
  const [sidenav, setSidenav] = useState(false);

  const location = useLocation();
  const toggle_Mode = () => {
    theme === "light" ? setTheme("dark") : setTheme("light");
    console.log("clicked");
  }

  useEffect(() => {
    let ResponsiveMenu = () => {
      if (window.innerWidth < 767) {
        setShowMenu(false);
      } else {
        setShowMenu(true);
      }
    };
    ResponsiveMenu();
    window.addEventListener("resize", ResponsiveMenu);
  }, []);

  return (
    <div className=" shadow-md  dark:bg-gray-900 fixed bg-white w-full justify-center space-between top-0 left-0 duration-200 z-50">
     <nav className="flex w-full top-0 left-0  items-center">
        <Flex className="container w-full flex justify-between items-center px-4 sm:px-6">
         <div className="navbar flex items-center">
            <Link to="/" className="flex top-0 items-start font-bold hover:text-accent transition ease-in-out">
              <img src={theme === "light" ? logo1_light : logo_dark} alt="Logo" className=" w-25 h-20 sm-w-40 sm-h-20 left-0" />
            </Link>
          </div>
          {/* Main NavBar */}

          <div className="navbar-list">
            {showMenu && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="flex items-center z-50"
              >
                {navBarList.map(({ _id, title, link }) => (
                  <NavLink
                    key={_id}
                    className="flex text-xl font-normal hover:font-bold justify-center items-center px-28 py-8 space-between hover:underline underline-offset-[4px] decoration-[1px] dark:hover:text-gray-300 white:hover:text-black last:border-r-0"
                    to={link}
                    state={{ data: location.pathname.split("/")[1] }}
                  >
                    {title}
                  </NavLink>
                ))}
              </motion.ul>
            )}

            <HiMenuAlt2
              onClick={() => setSidenav(!sidenav)}
              className="inline-block md:hidden cursor-pointer w-10 h-20 absolute top-0 justify-between items-center left-0 z-50"
            />

            {sidenav && (
              <div className="fixed  top-0 left-0 w-screen h-screen bg-black text-gray-200 bg-opacity-80 z-50">
                <motion.div
                  initial={{ x: -300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="w-[80%] bg-primeColor h-full p-6 relative"
                >
                  <div className=" W-FULL H-FULL mb-12">
                    <img className="w-30 h-20 " src={logo_dark} alt="Logo" />
                    <ul className="text-gray-200 flex flex-col gap-12">
                      {navBarList.map((item) => (
                        <li
                          className="font-normal gap-2 justify-center pl-10 pt-8 hover:font-bold items-center text-lg text-gray-200 hover:underline underline-offset-[4px] decoration-[1px] hover:text-white  hoverEffect last:border-r-0"
                          key={item._id}
                        >
                          <NavLink
                            to={item.link}
                            state={{ data: location.pathname.split("/")[1] }}
                            onClick={() => setSidenav(false)}
                          >
                            {item.title}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <span
                    onClick={() => setSidenav(false)}
                    className="w-8 h-8 border-[1px] border-gray-300 absolute top-2 -right-10 text-gray-300 text-2xl flex justify-center items-center cursor-pointer hover:border-red-500 hover:text-red-500 duration-300"
                  >
                    <MdClose />
                  </span>
                </motion.div>
              </div>
            )}
            </div>
              <div className="toggles relative top-0 left-0 right-8">
              <img
                onClick={() => {toggle_Mode()}}
                src={theme === "light" ? toggle_Light : toggle_Dark}
                alt="Toggle Theme"
                className="w-10 cursor-pointer transition-all duration-300"
              />
              </div>
        </Flex>
      </nav>
    </div>
  );
};

export default Header;
