import React, { useState } from 'react';
import styled from 'styled-components';

const ImageCarousel = ({ images, currentColor, setCurrentColor }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images[currentColor].length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images[currentColor].length) % images[currentColor].length);
  };

  const handleColorChange = (color) => {
    if (color !== currentColor) {
      setCurrentColor(color);
      setCurrentIndex(0); // Reset to the first image of the new color
    }
  };

  return (
    <CarouselWrapper>
      <div className="main-image">
        <img src={images[currentColor][currentIndex]} alt={`Product ${currentIndex + 1}`} />
        <button className="nav-button prev" onClick={prevImage}>❮</button>
        <button className="nav-button next" onClick={nextImage}>❯</button>
      </div>
      <div className="color-selection">
        {Object.keys(images).map(color => (
          <label key={color} className={`color-radio ${color === currentColor ? 'active' : ''}`}>
            <input
              type="radio"
              name="color"
              value={color}
              checked={color === currentColor}
              onChange={() => handleColorChange(color)}
            />
            <span className="color-indicator" style={{ backgroundColor: color }}></span>
            <span className="color-name">{color}</span>
          </label>
        ))}
      </div>
      <div className="thumbnails">
        {images[currentColor].map((img, index) => (
          <div
            key={index}
            className={`thumbnail ${index === currentIndex ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index)}
          >
            <img src={img} alt={`Thumbnail ${index + 1}`} />
          </div>
        ))}
      </div>
    </CarouselWrapper>
  );
};

const CarouselWrapper = styled.div`
  .main-image {
    position: relative;
    margin-bottom: 20px;
  }

  .main-image img {
    width: 100%;
    height: auto;
  }

  .nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
  }

  .prev { left: 4px; }
  .next { right: 4px; }

  .color-selection {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }

  .color-radio {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px;
  }

  .color-radio input[type="radio"] {
    display: none;
  }

  .color-indicator {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
    border: 2px solid #ccc;
  }

  .color-radio.active .color-indicator {
    border-color: gray;
    box-shadow: 0 0 0 2px gray;
  }

  .color-name {
    font-size: 14px;
  }

  .thumbnails {
    display: flex;
    gap: 10px;
    overflow-x: auto;
  }

  .thumbnail {
    flex: 0 0 auto;
    width: 60px;
    height: 60px;
    border: 1px solid #ddd;
    cursor: pointer;
  }

  .thumbnail.active {
    border-color: blue;
  }

  .thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: contain;
                                                                                                                                                                                        
  }
`;

export default ImageCarousel;

