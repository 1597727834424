import React from "react";
import styled from "styled-components";
import Highlight from "../../home/Products/Highlight";

const ProductInfo = ({ productInfo }) => {
  const { productName, highlights, input, color } = productInfo || {};
  
  if (!productName) {
    return null; // Handle case where productName is not defined
  }
 

 
  const renderDescription = () => {
    if (!productInfo.des) {
      return null; // or handle accordingly if product.des is not defined
    }

    return productInfo.des.split("\n").map((part, index) => {
      const [label, content] = part.split(":").map((item) => item.trim());
      return (
        <p key={index}>
          <span> {label}</span> {content}
        </p>
      );
    });
  };

  return (
    <Wrapper>
      <div className="product-info">
        <h2 className="product-name">{productName}</h2>

        <hr />
        <h5 className="text-xl font-extralight">{renderDescription()}</h5>
        <h3 className="py-4 text-2xl font-semibold">Feature Highlight:</h3>
        <div className="highlights-grid pt-4">
          {highlights && highlights.length > 0 ? (
            highlights.slice(0, 6).map((highlight, index) => (
              <Highlight
                key={index}
                img={highlight.img} // Pass the icon here
                title={highlight.title}
                description={highlight.description}
              />
            ))
          ) : (
            <p>No highlights available</p>
          )}
        </div>
        <hr />
        <p className="text-2xl sm:text-xl pt-4 product-details">
          <span>INPUT:</span> {input}
        </p>
        <p className="text-xl sm:text-xl product-details">
          {color}
          
        </p>
      </div>
    </Wrapper>
  );
};

export default ProductInfo;

// Styled component wrapper
const Wrapper = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-shadow: none; /* Remove box shadow */

  .product-info {
    padding: 20px;
    margin-top: 20px;
  }

  .product-description {
    font-size: 1.6rem;
    color: #666;
    margin-bottom: 20px;
  }

  .product-name {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .highlights-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 30px;
  }

  .product-details {
    font-size: 1.6rem;
    margin-bottom: 10px;

    p {
      margin: 5px 0;
    }

    span {
      padding-top: 10px;
      margin-top: 20px;
      font-weight: bold;
    }
  }
  @media (min-width: 768px) {
    .product-name {
      font-size: 2rem;
    }

    .product-description {
      font-size: 1.4rem;
    }

    .feature-highlight-title {
      font-size: 1.8rem;
    }

    .product-details {
      font-size: 1.4rem;
    }
  }

  @media (max-width: 868px) {
    .highlights-grid {
      grid-template-columns: repeat(2, 1fr);
    }
    .product-name {
      font-size: 1.8rem;
    }

    .product-description {
      font-size: 1.2rem;
    }

    .feature-highlight-title {
      font-size: 1.6rem;
    }

    .product-details {
      font-size: 1.2rem;
    }
  }
  .color-selection {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }

  .color-radio {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px;
  }

  .color-radio input[type="radio"] {
    display: none;
  }

  .color-indicator {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
    border: 2px solid #ccc;
    display: inline-block;
    background-color: blue; /* Ensure background color is set to white by default */
  }

  .color-radio.active .color-indicator {
    border-color: blue;
    box-shadow: 0 0 0 2px blue;
  }

  .color-name {
    font-size: 14px;
  }
`;
