//EARBUDS

import React from "react";
import { Link } from "react-router-dom";

const BestSellers = ({data}) => {
  
  
  return ( 
    <div className="min-h-[150px] flex justify-center items-center py-0 overflow-hidden"> 
    <div  className="container"> 
         <div style={{backgroundColor: data.bgColor}} className="grid grid-cols-1 md:grid-cols-3 gap-6 items-center banner-bg-color  rounded-3xl"> 
                 {/*first col */}
                 <div className='p-8 sm:p-8'>
                     <p data-aos="" className='text-3xl font-bold overflow-hidden'>{data.discount}</p>
                     <h1 data-aos="zoom-out" className='uppercase text-5xl lg:text-8xl font-bold'>{" "}{data.title}</h1>
                     <p data-aos="zoom-in"className='text-sm'>{data.date}</p>
                 </div>
                 {/*second col */}
                 <div>
                     <img src={data.image} alt="" data-aos="zoom-in" className=' scale-195
                      w-[650px] md:w-[640px] h-[550px] md:h-[500px] rounded-50 mx-auto drop-shadow-2xl object-cover'/>
                 </div>
                 {/*third col */}
                 <div className='flex flex-col justify-center gap-4 p-4 sm:p-8'>
                     <p data-aos="zoom-out"className='font-bold text-xl'>{data.title2}</p>
                     <h1 data-aos="zoom-in" className='text-3xl sm:text-5xl font-bold'>{data.title3}</h1>
                     <p data-aos="zoom-in" className='text-md tracking-wide leading-7'>{data.title4}</p>
                     <div data-aos="zoom-in" data-aos-offset="0">
                     <Link to={data.buttonLink}>
                     <button
                            className="bg-primary text-white hover:text-white dark:hover:text-white cursor-pointer hover:scale-105   py-2 px-4 rounded-3xl 
                            relative z-20  text-lg font-bodyFont  hover:bg-black duration-300 font-bold"
                          >
                         Shop Now
                     </button>
                 </Link >
                 </div>
                 </div>
 </div>
             </div>
         </div>
 
   );
 };
 

export default BestSellers;
