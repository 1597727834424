import React, { Suspense, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, Stage, useGLTF } from '@react-three/drei';

const Model = ({ color, modelPath }) => {
  const { scene } = useGLTF(modelPath);
  const ref = useRef();

  useFrame((state) => {
    if (ref.current) {
      const t = state.clock.getElapsedTime();
      ref.current.rotation.x = Math.cos(t / 4) / 8;
      ref.current.rotation.y = Math.sin(t / 4) / 8;
    }
  });

  React.useEffect(() => {
    scene.traverse((child) => {
      if (child.isMesh && child.material) {
        child.material.color.set(color);
      }
    });
  }, [scene, color]);

  return <primitive ref={ref} object={scene} />;
};

const ThreeDModelViewer = ({ color, modelPath }) => {
  return (
    <Canvas camera={{ position: [0, 0, 3] }}>
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <directionalLight position={[-2, 5, 2]} intensity={2.5} />
      
      <Suspense fallback={null}>
        <Stage intensity={0.05} environment="">
          <Model color="color" modelPath={modelPath} />
        </Stage>
        <OrbitControls enableZoom={true} enablePan={false} />
      </Suspense>
    </Canvas>
  );
};

export default ThreeDModelViewer;