import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { FaSearch, FaCaretDown, FaTimes } from "react-icons/fa";
import Flex from "../../designLayouts/Flex";
import { Link, useNavigate } from "react-router-dom";
import { paginationItems } from "../../../constants";

const HeaderBottom = () => {
  const [showCategories, setShowCategories] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const categoryRef = useRef();
  const searchRef = useRef();
  
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);

  useEffect(() => {
    console.log("paginationItems:", paginationItems); // Check if data is available
    const handleClickOutside = (e) => {
      if (categoryRef.current && !categoryRef.current.contains(e.target)) {
        setShowCategories(false);
      }
      if (searchRef.current && !searchRef.current.contains(e.target)) {
        setShowSearchBar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("scroll", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("scroll", handleClickOutside);
    };
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setShowSearchBar(true);

    const filtered = paginationItems.filter((item) =>
      item.productName.toLowerCase().includes(query.toLowerCase()) ||
      (item.category && item.category.toLowerCase().includes(query.toLowerCase()))
    );
    console.log("Filtered products:", filtered); // Check filtered results
    setFilteredProducts(filtered);
  };

  const clearSearch = () => {
    setSearchQuery("");
    setShowSearchBar(false);
    setFilteredProducts([]);
  };

  const handleProductClick = (item) => {
    navigate(`/product/${item.productName.toLowerCase().split(" ").join("")}`, {
      state: { item: item },
    });
    clearSearch();
    setShowSearchBar(false);
  };



  return (
    <div className="hidden sm:block  relative">
      <div className="container mx-auto py-[24px] pb-0   gap-4  justify-items-center">
        <Flex className="flex flex-col lg:flex-row  items-center  justify-between">
          <div className="relative cursor-pointer group" ref={categoryRef}>
            <div
              onClick={() => setShowCategories(!showCategories)}
              className="flex h-[60px]  cursor-pointer items-center gap-2 text-primeColor"
            >
              <a
                href="#"
                className="flex items-center gap-2  px-4 pl-10 group h-[72px] text-2xl
           text-black dark:text-white hover:text-black dark:hover:text-white duration-300  "
              >
                Shop by Category { " " }
                <span>
                  <FaCaretDown className={`duration-300 ${showCategories ? 'rotate-180' : ''}`}  />
                </span>
              </a>
              {showCategories && (
                <motion.ul
                  initial={{ y: 30, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="absolute Z-[9999] hidden w-[200px] group-hover:block shadow-md top-[60px] z-50 bg-primeColor text-[#767676] p-3 pb-4"
                >
                  <Link to="/category/Earbuds">
                  <li class="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer"
                  >Earbuds</li>
                  </Link>
                

                  <Link to="/category/Speakers">
                    <li onClick={() => setShow(false)} className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                      Party Speakers
                    </li>
                  </Link>

                  <Link>
                    <li onClick={() => setShow(false)} className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                      Smart Watches
                    </li>
                  </Link>
                </motion.ul>
              )}
            </div>
          </div>

          {/* Search bar */}
          <div className="relative group flex sm:block h-10" ref={searchRef} >
            <input
              className="flex outline-none search-bar placeholder:text-[#C4C4C4] placeholder:text-[12px]"
              type="text"
              onChange={handleSearch}
              value={searchQuery}
              placeholder="Search your products here"
            />
            {searchQuery ? (
              
              <FaTimes
                className="text-2xl cursor-pointer text-gray-600 group-hover:text-primary
                dark:text-gray-400 absolute top-1/2 right-3 duration-500 
                translate-y-[-50%] transition-all"
                onClick={clearSearch}
              />
            ) : (
              <FaSearch
                className="text-2xl cursor-pointer text-gray-600 group-hover:text-primary
                dark:text-gray-400 absolute top-1/2 right-3 duration-500 
                translate-y-[-50%] transition-all"
              />
            )}
            {showSearchBar && searchQuery && (
              <div className="absolute w-full mx-auto max-h-96 top-full left-0 z-50 overflow-y-auto shadow-2xl scrollbar-hide cursor-pointer bg-white">
                {filteredProducts.length > 0 ? (
                  filteredProducts.map((item) => (
                    <motion.div
                    initial={{ opacity: 0, scaleY: 0.9 }}
                    animate={{ opacity: 1, scaleY: 1 }}
                    transition={{ duration: 0.3 }}
                      onClick={() => handleProductClick(item)}
                      key={item._id}
                      className="max-w-[600px] h-28 bg-white dark:text-black duration-200 hover:bg-gray-100 dark:hover:bg-gray-600 mb-3 flex items-center gap-3 p-2"
                    >
                      <img className="w-24 h-24 object-contain" src={item.img} alt="productImg" />
                      <div className="flex flex-col gap-1">
                        <p className="font-semibold text-lg">{item.productName}</p>
                        <p className="text-xs">{item.category}</p>
                        <p className="text-xs">
                          {item.des && item.des.length > 100
                            ? `${item.des.slice(0, 100)}...`
                            : item.des}
                        </p>
                      </div>
                    </motion.div>
                  ))
                ) : (
                  <div className="p-4 text-center text-red-500">No products found</div>
                )}
              </div>
            )}
          </div>
        </Flex>
      </div>
    </div>
  );
};

export default HeaderBottom;
 