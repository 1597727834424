import React from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  BTpod1,
  BTpod2,
  BTpod3,
  BTpod4,
  BCpod1,
  BCpod2,
  BCpod3,
  BCpod4,
  BSmart1,
  BSmart2,
  BSmart3,
  BSmart4,
  WSmart1,
  WSmart2,
  WSmart3,
  WSmart4,

  Notfounding,

  // Speaker section
  NBS1002,
  NBS1202,
  S1002,
  S1202,
  
// icon svg section
  Icon1,
  ANC,
  ENC,
  Battery,
  Calldecline,
  Callrecive,
  Charge,
  Ipx4,
  Mode,
  Playpause,
  Sing,
  Tap,
  Volumeminus,
  Volumeplus,
  Umbrella,
  Mics,
  AMP,
  Speaker,
  Dual,
  Eq,


} from "../../../assets/images/index";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";
import { Link } from "react-router-dom";

const NewArrivals = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow  />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className="container px-4 mx-auto relative ">
    <div className="pb-10 pt-20  mx-auto z-10">
      <Link to="/shop" className="hover:text-black dark:hover:text-white duration-200 p-2   font-titleFont font-semibold text-lg">
      <Heading heading="New Arrivals" subtitle="View All" />
      </Link>

        <div className=" max-h-full max-w-fit  hover:text-black dark:hover:text-white duration-200   font-titleFont font-semibold  overflow-y-hidden ">
             <Slider {...settings}>
        <div className="object-cover relative group ">
         <Product
            _id="100001"
           img={NBS1002}
           images={{
            black: [NBS1002, S1002],
            // other colors...
          }}     
            productName="PARTY SPEAKER NBS1002" 
            badge={true}
            input="50W   || OUTPUT WATTAGE :160W"
            des="Get ready to experience sound like never before with NBS1002. With 6 Equaliser modes, you can customise your audio experience to suit your preferences. From punchy bass to crystal-clear highs, the NBS1002 delivers unparalleled sound quality. Trust us, once you hear it, you won’t want to go back to anything else."
            highlights= {[   { img: Sing, title: "SIGNATURE", description: "SOUND" },
              { img: Mics,  title: "WIRELESS MICS", description: "2" },
              { img: Speaker,  title: "DYNAMIC", description: "LIGHTS" }, 
              { img: AMP,  title: "AMP", description: "CLASS D" },
              { img: Eq,  title: "EQ MODES ", description: "6" },
              { img: Dual,  title: "DUAL", description: "10\"" },
              ]}

            ficheTech={[{ label: "MIC INPUT", value: "2" },
              { label: "WIRELESS MIC", value: "2" },
              { label: "RF POWER", value: "10mV" },
              { label: "BATTERY", value: "12V" },
              { label: "BATTERY BACKUP", value: "UP TO 16 HOURS" },
              { label: "CHARGING TIME", value: "5 HOURS" },
              { label: "POWER SUPPLY", value: "5W" },
              { label: "FREQUENCY RESPONSE", value: "50HZ TO 20 KHZ" },
              { label: "CABINET", value: "PP" },
              { label: "HF DRIVER", value: "1\"" },
              { label: "SPEAKER", value: "DUAL 10\"" },
              { label: "REMOTE OPERATION", value: "YES" },
              { label: "EQ MODES", value: "6" },
              { label: "USB PORTS", value: "1" },
              { label: "TF CARD PORT", value: "1" },
              { label: "AUX", value: "1" },
              { label: "LINE OUT", value: "STEREO" },
              { label: "WORKING VOLTAGE", value: "100V - 260V" },
              { label: "NET WEIGHT", value: "18 KG" },]}
              features={[  { label: "Independent Bass & High Settings for Mics & Audio" },
                { label: "Instrument Input for Solo Performance" },
                { label: "Dynamic LED Lights with 6 Modes" },
                { label: "Inbuilt FM, Aux Input, Line Input" },
                { label: "Preoutput to Connect Multiple Speakers" },
                { label: "Mic Priority for Public Announcement" },
                { label: "Universal Power Supply" },
                { label: "6 EQ Modes" },
                { label: "TF Card Reader for Playback & Real Time Recording" },]}  
                boxs= { [
                  { label: "2 Microphones", description: "3 different sound modes" },
                  { label: "Charging Cable", description: "3 different display options" },
                  { label: "Remote", description: "Active Noise Cancellation (ANC), Environmental Noise Cancellation (ENC), and immersive 360-degree audio" },]}
         /> 
        </div>
        <div className="object-cover relative group ">
          <Product
            _id="100002"
            img={BTpod1}
            productName="EARBUDS T-PODS"
            images={{
              black: [BTpod1, BTpod2, BTpod3, BTpod4],
              // other colors...
            }}         
            badge={true}
            input="5V DC  ||   PORT: TYPE C"
            des="Treat your ears to the ultimate sound experience with T-pods. Equipped with ANC and ENC, It delivers crystal-clear sound and offers unparalleled convenience and performance. Try it and discover why they're the perfect choice for all your listening needs."
            highlights= {[  { img: Sing, title: "SIGNATURE", description: "SOUND" },
              { img: ANC,  title: "MODE", description: "ANC" },
              { img: Umbrella,  title: "WATERPROOF", description: "IPX4" }, 
              { img: Mics,  title: "MICS", description: "4" },
              { img: ENC,  title: "MODE", description: "ENC" },
              { img: Charge,  title: "FAST CHARGING ", description: "45 MINS" },
              ]}
              controls= {[  { img: Volumeplus, title: "Tap + Hold", description: "Right 1.5 Seconds" },
                { img: Callrecive,  title: "2 Tap", description: "Right Receive" },
                { img: Playpause,  title: "2 Tap", description: "Play/Pause" }, 
                { img: Volumeminus,  title: "TAP + Hold", description: "Left 1.5 Seconds" },
                { img: Calldecline,  title: "2 Tap", description: "Left Decline" },
                { img: Mode,  title: "3 Tap ", description: "Mode Changes" },
                ]}
            ficheTech={[  { label: "TOTAL MICS", value: "4" },
              { label: "FREQ. BAND", value: "2.4GHZ" },
              { label: "INPUT CONNECTOR", value: "C TYPE" },
              { label: "CASE BATTERY", value: "400 MAH" },
              { label: "CASE CHARGING TIME", value: "45 MINS" },
              { label: "BUDS CHARGING TIME", value: "30 MINS" },
              { label: "BUDS BATTERY", value: "30 MAH" },
              { label: "FREQUENCY RESPONSE", value: "5HZ TO 21 KHZ" },
              { label: "CASE", value: "ABS" },
              { label: "DRIVER", value: "10MM" },
              { label: "DRIVER TYPE", value: "COAX" },
              { label: "TAP CONTROLS", value: "YES" },
              { label: "SIGNATURE SOUND", value: "YES" },
              { label: "AUTO ENC", value: "YES" },
              { label: "WORKING DISTANCE", value: "15 METERS" },
              { label: "WORKING VOLTAGE", value: "5V" },]}
              
              features={[  { label: "3 Sound Modes", description: "3 different sound modes" },
                { label: "Inbuilt ANC, ENC", description: "Active Noise Cancellation (ANC), Environmental Noise Cancellation (ENC), and immersive 360-degree audio" },
                { label: "Auto Noise Adjustment", description: "Automatically adjusts noise levels" },
                { label: "Multi Tap Controls", description: "Intuitive controls with multi-tap gestures" },
                { label: "45 Minutes Fast Charging", description: "45 minutes for a full charge" },
                { label: "Upto 120 Hours of Playback Time", description: "Up to 120 hours of continuous playback" },]}
         
                boxs= {[
                  { label: "Charging Cable", description: "3 different sound modes" },
      { label: "Earphones", description: "3 different display options" },
      { label: "Charging Case", description: "Active Noise Cancellation (ANC), Environmental Noise Cancellation (ENC), and immersive 360-degree audio" },
      { label: "Ear Cap (Size: Small, Medium, Large )", description: "Automatically adjusts noise levels" },]}
         
         />
        </div>
        <div className="object-cover relative group ">
          <Product
            _id="100003"
            productName="PARTY SPEAKER NBS1202"
            img={NBS1202}
            images={{
             black: [NBS1202, S1202],
             // other colors...
           }}           
          
            badge={true}
            input="50W   || OUTPUT WATTAGE :160W" 
            des="Get ready to take your audio experience to new heights with NBS1202 - a premium party speaker that delivers unparalleled sound quality which comes with built-in features like Vocal cancellation, Mic priority and Dynamic LED lights and many more.From the thump of deep bass to the crispness of high frequencies, the NBS1202 offers a truly exceptional listening experience.Trust us, once you hear the difference, you'll wonder how you ever lived without it."
            highlights= {[   { img: Sing, title: "SIGNATURE", description: "SOUND" },
              { img: Mics,  title: "WIRELESS MICS", description: "2" },
              { img: Speaker,  title: "DYNAMIC", description: "LIGHTS" }, 
              { img: AMP,  title: "AMP", description: "CLASS D" },
              { img: Eq,  title: "INBUILT ", description: "EFX" },
              { img: Dual,  title: "DUAL", description: "12\"" },
              ]}
            
            ficheTech={[{ label: "MIC INPUT", value: "2" },
              { label: "WIRELESS MIC", value: "2" },
              { label: "RF POWER", value: "10mV" },
              { label: "BATTERY", value: "12V" },
              { label: "BATTERY BACKUP", value: "UP TO 18 HOURS" },
              { label: "CHARGING TIME", value: "8 HOURS" },
              { label: "POWER SUPPLY", value: "120W" },
              { label: "FREQUENCY RESPONSE", value: "50HZ TO 20 KHZ" },
              { label: "CABINET", value: "PP" },
              { label: "HF DRIVER", value: "1\"" },
              { label: "SPEAKER", value: "DUAL 12\"" },
              { label: "REMOTE OPERATION", value: "YES" },
              { label: "EQ MODES", value: "2" },
              { label: "USB PORTS", value: "2" },
              { label: "TF CARD PORT", value: "1" },
              { label: "AUX", value: "1" },
              { label: "LINE OUT", value: "STEREO" },
              { label: "WORKING VOLTAGE", value: "100V - 260V" },
              { label: "NET WEIGHT", value: "24 KG" },]}
              features={[    { label: "Independent Bass & High Settings for Mics & Audio" },
                { label: "Instrument Input for Solo Performance" },
                { label: "Vocal cancelation for Karaoke applications" },
                { label: "Dynamic LED Lights with 6 Modes" },
                { label: "Inbuilt FM, Aux Input, Line Input" },
                { label: "Preoutput to Connect Multiple Speakers" },
                { label: "Mic Priority for Public Announcement" },
                { label: "Universal Power Supply" },
                { label: "2 EQ Modes" },
                { label: "TF Card Reader for Playback & Real Time Recording" },]}

                boxs= { [
                  { label: "2 Microphones", description: "3 different sound modes" },
                  { label: "Charging Cable", description: "3 different display options" },
                  { label: "Remote", description: "Active Noise Cancellation (ANC), Environmental Noise Cancellation (ENC), and immersive 360-degree audio" },]}
          />
        </div>
        <div className="object-cover relative group  ">
          <Product
            _id="100004"
            img={BCpod1}
            productName="EARBUDS C-PODS"
            input="5V DC  ||  PORT: TYPE C"
            images={{
              black: [BCpod1, BCpod2, BCpod3, BCpod4],
              // other colors...
            }}    
            modelPath="/moddel/CPOD.glb"
            badge={false}
            des="Hear your music like never before with C-pods, the ultimate wireless earbuds. With ANC, ENC, and Transparency Mode, you'll enjoy crystal-clear sound and complete immersion in your music. Plus, its powerful bass will take your listening experience to the next level."
            highlights= {[  { img: Sing, title: "SIGNATURE", description: "SOUND" },
              { img: ANC,  title: "MODE", description: "ANC" },
              { img: Umbrella,  title: "WATERPROOF", description: "IPX4" }, 
              { img: Mics,  title: "MICS", description: "4" },
              { img: ENC,  title: "MODE", description: "ENC" },
              { img: Charge,  title: "FAST CHARGING ", description: "45 MINS" },
              ]}
              controls= {[  { img: Volumeplus, title: "Tap + Hold", description: "Right 1.5 Seconds" },
                { img: Callrecive,  title: "2 Tap", description: "Right Receive" },
                { img: Playpause,  title: "2 Tap", description: "Play/Pause" }, 
                { img: Volumeminus,  title: "TAP + Hold", description: "Left 1.5 Seconds" },
                { img: Calldecline,  title: "2 Tap", description: "Left Decline" },
                { img: Mode,  title: "3 Tap ", description: "Mode Changes" },
                ]}
            ficheTech={[ { label: "TOTAL MICS", value: "4" },
              { label: "FREQ. BAND", value: "2.4GHZ" },
              { label: "INPUT CONNECTOR", value: "C TYPE" },
              { label: "CASE BATTERY", value: "400MAH" },
              { label: "CASE CHARGING TIME", value: "45 MINS" },
              { label: "BUDS CHARGING TIME", value: "30 MINS" },
              { label: "BUDS BATTERY", value: "50W" },
              { label: "FREQUENCY RESPONSE", value: "5HZ TO 21 KHZ" },
              { label: "CASE", value: "ABS" },
              { label: "DRIVER", value: "10MM" },
              { label: "DRIVER TYPE", value: "COAX" },
              { label: "TAP CONTROLS", value: "YES" },
              { label: "SIGNATURE SOUND", value: "YES" },
              { label: "AUTO ENC", value: "YES" },
              { label: "WORKING DISTANCE", value: "15 METERS" },
              { label: "WORKING VOLTAGE", value: "5V" },]}
              features={[  
                { label: "3 Sound Modes", description: "3 different sound modes" },
               
                
                { label: "Inbuilt ANC, ENC", description: "Active Noise Cancellation (ANC), Environmental Noise Cancellation (ENC), and immersive 360-degree audio" },
                { label: "Auto Noise Adjustment", description: "Automatically adjusts noise levels" },
                { label: "Multi Tap Controls", description: "Intuitive controls with multi-tap gestures" },
                { label: "45 Minutes Fast Charging", description: "45 minutes for a full charge" },
                { label: "Upto 120 Hours of Playback Time", description: "Up to 120 hours of continuous playback" },]}
                boxs= {[
                  { label: "Charging Cable", description: "3 different sound modes" },
                  { label: "Earphones", description: "3 different display options" },
                  { label: "Charging Case", description: "Active Noise Cancellation (ANC), Environmental Noise Cancellation (ENC), and immersive 360-degree audio" },
                  { label: "Ear Cap (Size: Small, Medium, Large )", description: "Automatically adjusts noise levels" },]}
          />
        </div>
        <div className=" object-cover relative group ">
          <Product
            _id="100005"
            img={BSmart1}
            productName="EARBUD SMART-POD"
           input="5V DC  ||  PORT: TYPE C"
          images={{
            black: [BSmart1, BSmart2, BSmart3, BSmart4],
            white: [WSmart1, WSmart2, WSmart3, WSmart4],
            // other colors...
          }}    
          modelPath="/moddel/SmartWhite.glb"
            badge={false}
            des="Experience the future of audio with Nbuzz Smart Pods. It comes with 8 Adjustable EQs, 3 sound modes, earbuds finder and many more, Smart Pods deliver unmatched sound quality and customization. It is the ultimate choice for those who value excellence in audio. Upgrade your listening experience today."
            highlights= {[  { img: Sing, title: "SIGNATURE", description: "SOUND" },
              { img: ANC,  title: "MODE", description: "ANC" },
              { img: Tap,  title: "DIGITAL DISPLAY", description: "1.32" }, 
              { img: Mics,  title: "MICS", description: "4" },
              { img: ENC,  title: "MODE", description: "ENC" },
              { img: Charge,  title: "FAST CHARGING ", description: "45 MINS" },
              ]}
              controls= {[  { img: Volumeplus, title: "Tap + Hold", description: "Right 1.5 Seconds" },
                { img: Callrecive,  title: "2 Tap", description: "Right Receive" },
                { img: Playpause,  title: "2 Tap", description: "Play/Pause" }, 
                { img: Volumeminus,  title: "TAP + Hold", description: "Left 1.5 Seconds" },
                { img: Calldecline,  title: "2 Tap", description: "Left Decline" },
                { img: Mode,  title: "3 Tap ", description: "Mode Changes" },
                ]}
            ficheTech={[
              { label: "TOTAL MICS", value: "4" },
              { label: "FREQ. BAND", value: "2.4GHZ" },
              { label: "INPUT CONNECTOR", value: "C TYPE" },
              { label: "CASE BATTERY", value: "400MAH" },
              { label: "CASE CHARGING TIME", value: "45 MINS" },
              { label: "BUDS CHARGING TIME", value: "30 MINS" },
              { label: "BUDS BATTERY", value: "40MAH" },
              { label: "FREQUENCY RESPONSE", value: "5HZ TO 21 KHZ" },
              { label: "CASE", value: "ABS" },
              { label: "DRIVER", value: "14MM" },
              { label: "DRIVER TYPE", value: "COAX" },
              { label: "TAP CONTROLS", value: "YES" },
              { label: "EQ MODES", value: "8" },
              { label: "ATMOSPHERIC MODES", value: "3" },
              { label: "SIGNATURE SOUND", value: "YES" },
              { label: "AUTO ENC", value: "YES" },
              { label: "WORKING DISTANCE", value: "15 METERS" },
              { label: "WORKING VOLTAGE", value: "5V" },]}
              
              features={[  { label: "8 Adjustable EQs", description: "8 different equalizer settings" },
                { label: "3 Sound Modes", description: "3 different sound modes" },
                { label: "Earbuds Finder", description: "Built-in feature to locate earbuds" },
                { label: "3 Display Modes", description: "3 different display options" },
                { label: "Inbuilt ANC, ENC ", description: "Active Noise Cancellation (ANC), Environmental Noise Cancellation (ENC), and immersive 360-degree audio" },
                { label: "Auto Noise Adjustment", description: "Automatically adjusts noise levels" },
                { label: "Multi Tap Controls", description: "Intuitive controls with multi-tap gestures" },
                { label: "45 Minutes Fast Charging", description: "45 minutes for a full charge" },
                { label: "Upto 80 Hours of Playback Time", description: "Up to 80 hours of continuous playback" },]}
                boxs= {[
                  { label: "Charging Cable", description: "3 different sound modes" },
                  { label: "Earphones", description: "3 different display options" },
                  { label: "Charging Case", description: "Active Noise Cancellation (ANC), Environmental Noise Cancellation (ENC), and immersive 360-degree audio" },
                  { label: "Ear Cap (Size: Small, Medium, Large )", description: "Automatically adjusts noise levels" },]}
          />
        </div>

         </Slider>
      </div>
     </div>
    </div>
  );
};

export default NewArrivals;
