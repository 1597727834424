import React from "react";
import styled from "styled-components";

const Highlight = ({img , title, description }) => {
  return (
    <HighlightWrapper>
                {img && <img src={img} alt={title} className="w-50 h-50 highlight-icon" /> }
                 <h5 className="highlight-title">{title}</h5>
                 <p className="highlight-description">{description}
                 </p>
     </HighlightWrapper>       
  );
};

export default Highlight;

// Styled component wrapper
const HighlightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .highlight-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    background-color: rgba(220, 220, 220, 0.5);
    border-radius: 50%;
    padding: 0.6rem;
  }

  .highlight-title {
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase;
  }
`;


